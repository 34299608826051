// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-organizer-js": () => import("./../../../src/pages/about-organizer.js" /* webpackChunkName: "component---src-pages-about-organizer-js" */),
  "component---src-pages-evaluation-criteria-js": () => import("./../../../src/pages/evaluation-criteria.js" /* webpackChunkName: "component---src-pages-evaluation-criteria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-js": () => import("./../../../src/pages/inquiry.js" /* webpackChunkName: "component---src-pages-inquiry-js" */),
  "component---src-pages-judging-panel-js": () => import("./../../../src/pages/judging-panel.js" /* webpackChunkName: "component---src-pages-judging-panel-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */)
}

